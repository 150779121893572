import AppLayout, { AppLayoutProps } from '@amzn/awsui-components-react/polaris/app-layout';
import Flashbar, { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import without from 'lodash/without';
import React, { createContext, Dispatch, Reducer, useContext, useReducer, useState } from 'react';

import Navigation from './Navigation';

interface LayoutProps extends Omit<AppLayoutProps, 'notifications'> {
  content: React.ReactNode;
  notifications?: FlashbarProps.MessageDefinition[];
}

const Layout: React.FunctionComponent<LayoutProps> = (props) => {
  const defaults: AppLayoutProps = {
    contentType: 'default',
    disableContentPaddings: false,
    navigationHide: false,
    navigationOpen: true,
  };

  const { contentType, disableContentPaddings, navigationHide, navigationOpen } = {
    ...defaults,
    ...props,
  };

  const [navigationOpenState, setNavigationOpenState] = useState(navigationOpen);
  const { notifications, tools } = useLayoutContext();
  const layoutDispatch = useLayoutDispatch();

  const notices: FlashbarProps.MessageDefinition[] = [...(props.notifications ?? []), ...notifications];

  return (
    <AppLayout
      {...props}
      contentType={contentType}
      disableContentPaddings={disableContentPaddings}
      navigation={<Navigation />}
      navigationHide={navigationHide}
      navigationOpen={navigationOpenState}
      onNavigationChange={(evt) => setNavigationOpenState(evt.detail.open)}
      content={props.content}
      notifications={<Flashbar items={notices} />}
      toolsHide={tools.length <= 0}
      toolsOpen={tools.length > 0}
      onToolsChange={({ detail: { open } }) => {
        if (!open) {
          layoutDispatch({ type: 'clearTools' });
        }
      }}
      tools={tools}
    />
  );
};

// State context

interface LayoutState {
  notifications: FlashbarProps.MessageDefinition[];
  tools: React.ReactNode[];
}

const initialState: LayoutState = {
  notifications: [],
  tools: [],
};

const LayoutContext = createContext<LayoutState>(initialState);

// Dispatch context / reducer

type Action =
  | { type: 'addNotification'; notification: FlashbarProps.MessageDefinition }
  | { type: 'removeNotification'; notification: FlashbarProps.MessageDefinition }
  | { type: 'clearNotifications' }
  | { type: 'openTool'; tools: React.ReactNode[] }
  | { type: 'clearTools' };

const reducer: Reducer<LayoutState, Action> = (state: LayoutState, action: Action) => {
  switch (action.type) {
    case 'addNotification':
      return {
        ...state,
        notifications: [action.notification, ...state.notifications],
      };
    case 'removeNotification': {
      const existing = state.notifications.find(
        (notification) =>
          'uuid' in notification &&
          'uuid' in action.notification &&
          (notification as Notification).uuid === (action.notification as Notification).uuid,
      );
      if (existing !== undefined) {
        return {
          ...state,
          notifications: without(state.notifications, existing),
        };
      } else {
        return state;
      }
    }
    case 'clearNotifications':
      return {
        ...state,
        notifications: [],
      };
    case 'openTool':
      return {
        ...state,
        tools: [action.tools, ...state.tools],
      };
    case 'clearTools':
      return {
        ...state,
        tools: [],
      };
    default:
      throw new Error(`Unknown action: ${action}`);
  }
};

const LayoutDispatchContext = createContext<Dispatch<Action>>(null as unknown as Dispatch<Action>);

// Exposed methods for the consumer

export type Notification = FlashbarProps.MessageDefinition & {
  uuid?: string;
};

export const useLayoutContext = () => useContext(LayoutContext);
export const useLayoutDispatch = () => useContext(LayoutDispatchContext);

export function LayoutProvider(props: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <LayoutContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>{props.children}</LayoutDispatchContext.Provider>
    </LayoutContext.Provider>
  );
}

export default Layout;
