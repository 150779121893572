import Alert from '@amzn/awsui-components-react/polaris/alert';
import Box from '@amzn/awsui-components-react/polaris/box';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import Layout from './Layout';

function ErrorBoundaryContent() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <Box margin={{ bottom: 'l' }}>
          <Alert type='error' header='Not found'>
            This page doesn't exist.
          </Alert>
        </Box>
      );
    }
  }

  return (
    <Box margin={{ bottom: 'l' }}>
      <Alert type='error' header='Uh oh'>
        Pasta Service ran out of yolks.
      </Alert>
    </Box>
  );
}

export default function ErrorBoundary() {
  return <Layout content={<ErrorBoundaryContent />} />;
}
