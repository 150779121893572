import FormField, { FormFieldProps } from '@amzn/awsui-components-react/polaris/form-field';
import { InputProps } from '@amzn/awsui-components-react/polaris/input';
import { ControlledInput } from '@amzn/react-hook-form-polaris';
import { get, useFormContext } from 'react-hook-form';

interface ControlledFormInputProps {
  name: string;
  optional?: boolean;
  fieldProps?: FormFieldProps;
  inputProps?: Omit<InputProps, 'value'>;
}

export default function ControlledFormInput(props: ControlledFormInputProps) {
  const { name, optional, fieldProps, inputProps } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const label = fieldProps && fieldProps.label;

  return (
    <FormField
      {...fieldProps}
      label={
        optional ? (
          <span>
            {label} <i>- optional</i>
          </span>
        ) : (
          label
        )
      }
      errorText={get(errors, `${name}.message`)}
    >
      <ControlledInput {...inputProps} control={control} name={name} />
    </FormField>
  );
}
