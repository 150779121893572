import { Metadata, Operation } from '@amzn/pasta-service-typescript-client/structures';
import axios, { AxiosError, AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import { LoaderFunctionArgs } from 'react-router-dom';

import { settingsLoader } from '../App';

type RequestConfig = AxiosRequestConfig & {
  _retryAttempt: boolean;
};

/**
 * Operation executor used in Typescript Client
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default async function operationExecutor(metadata: Metadata, operation: Operation, request?: any): Promise<any> {
  const data = JSON.stringify(request);
  const method = 'POST';

  // If we load a nested route, the settings haven't been loaded yet.
  // Make sure that they are.
  if (window.SETTINGS === undefined) {
    await settingsLoader({} as LoaderFunctionArgs);
  }

  const url = `${window.SETTINGS.apiEndpoint}/${operation.name}`;
  const withCredentials = false;
  const axiosInstance = axios.create({
    method,
    withCredentials,
  });

  axiosInstance.interceptors.response.use(
    (res) => res,
    async (err) => {
      const { config, response } = err as AxiosError;
      if (config !== undefined && response !== undefined) {
        if (response.status === 401 && !(config as RequestConfig)._retryAttempt) {
          const retry: RequestConfig = {
            ...config,
            _retryAttempt: true,
            headers: {
              ...(config.headers as RawAxiosRequestHeaders),
            },
          };
          return axiosInstance.request(retry);
        }
        return Promise.reject(response.data);
      }
      return Promise.reject(err);
    },
  );

  return (await axiosInstance.post(url, data)).data;
}
