import SideNavigation, { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { UIPath } from '../paths';

const navHeader: SideNavigationProps.Header = { text: 'Pasta Service', href: UIPath.Landing };

const Navigation: React.FunctionComponent = () => {
  const navItems: Array<SideNavigationProps.Item> = [
    { type: 'link', text: 'Home', href: UIPath.Landing },
    { type: 'divider' },
    { type: 'link', text: 'Log Pasta', href: UIPath.CreatePasta },
    { type: 'link', text: 'Pasta Catalog', href: UIPath.ListPasta },
  ];
  const location = useLocation();
  const href = location.pathname.startsWith(UIPath.Landing) ? UIPath.Landing : location.pathname;
  return <SideNavigation header={navHeader} items={navItems} activeHref={href} />;
};

export default Navigation;
