import { Alert, BreadcrumbGroup, ContentLayout } from '@amzn/awsui-components-react';
import Box from '@amzn/awsui-components-react/polaris/box';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Table from '@amzn/awsui-components-react/polaris/table';
import { Pasta } from '@amzn/pasta-service-typescript-client';
import { useEffect, useState } from 'react';

import pastaServiceClient from '../api/client';
import Layout from '../components/Layout';
import { UIPath } from '../paths';

function PastaTable() {
  const [pastas, setPastas] = useState<Pasta[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const loadPasta = async () => {
    return pastaServiceClient
      .listPasta({})
      .then((response) => {
        setPastas(response.pastas!);
        setError(false);
      })
      .catch(() => {
        setError(true);
      });
  };

  // To load the first page of pasta
  useEffect(() => {
    loadPasta().finally(() => setLoading(false));
  }, []);

  return (
    <div>
      <SpaceBetween size='xs'>
        <Table
          header={<Header variant='h2'>Pasta Catalog</Header>}
          loading={loading}
          items={pastas}
          wrapLines
          resizableColumns
          stickyHeader
          empty={
            <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
              No pasta to display.
            </Box>
          }
          columnDefinitions={[
            {
              id: 'name',
              header: 'Name',
              cell: (e: Pasta) => e.name,
            },
            {
              id: 'description',
              header: 'Description',
              cell: (e: Pasta) => e.description,
            },
            {
              id: 'timesEaten',
              header: 'Times Eaten',
              cell: (e: Pasta) => e.timesEaten,
            },
            {
              id: 'rating',
              header: 'Rating',
              cell: (e: Pasta) => e.rating,
            },
          ]}
        />
        {error && (
          <Alert
            type='error'
            dismissible
            onDismiss={() => {
              setError(false);
            }}
            header='Uh oh'
          >
            Something went wrong when trying to load your pasta.
          </Alert>
        )}
      </SpaceBetween>
    </div>
  );
}

function PastaCatalogLayout() {
  return (
    <Layout
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'Home', href: UIPath.Landing },
            { text: 'Pasta Catalog', href: UIPath.ListPasta },
          ]}
          ariaLabel='Breadcrumbs'
        />
      }
      content={
        <ContentLayout header={<Header variant='h1'>Pasta Catalog</Header>}>
          <SpaceBetween size='l'>
            <PastaTable />
          </SpaceBetween>
        </ContentLayout>
      }
    />
  );
}

export default PastaCatalogLayout;
