import { createBrowserRouter, createRoutesFromElements, LoaderFunction, Route, RouterProvider } from 'react-router-dom';

import ErrorBoundary from './components/ErrorBoundary';
import { LayoutProvider } from './components/Layout';
import CreatePasta from './pages/CreatePasta';
import Landing from './pages/Landing';
import PastaCatalogLayout from './pages/ListPasta';
import { UIPath } from './paths';

interface Settings {
  apiEndpoint: string;
  region: string;
  stage: string;
}

declare global {
  interface Window {
    SETTINGS: Settings;
  }
}

export const settingsLoader: LoaderFunction = async () => {
  if (window.SETTINGS !== undefined) return;

  // Any error loading and parsing the settings will fallback to the
  // error element of the root route below and render an error.
  const settings: Settings = await (await fetch('/settings.json')).json();
  if (!/^https:/.test(settings.apiEndpoint)) {
    console.error(`Unexpected value for API endpoint in settings.json: ${settings.apiEndpoint}`);
    throw new Error('Bad settings.json');
  }
  window.SETTINGS = settings;
  return;
};

export default function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<ErrorBoundary />} loader={settingsLoader}>
        <Route path={UIPath.Landing} element={<Landing />} />
        <Route path={UIPath.CreatePasta} element={<CreatePasta />} />
        <Route path={UIPath.ListPasta} element={<PastaCatalogLayout />} />
      </Route>,
    ),
  );

  return (
    <div className='awsui-visual-refresh'>
      <LayoutProvider>
        <RouterProvider router={router} />
      </LayoutProvider>
    </div>
  );
}
