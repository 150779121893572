import { BreadcrumbGroup, Container, FlashbarProps, SpaceBetween, Wizard } from '@amzn/awsui-components-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { uniqueId } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { InferType, number, object, string } from 'yup';

import pastaServiceClient from '../api/client';
import { PastaServiceApiError } from '../api/types';
import ControlledFormInput from '../components/ControlledFormInput';
import Layout, { Notification, useLayoutDispatch } from '../components/Layout';
import { BreadcrumbText, UIPath } from '../paths';

// Form schema for the wizard
const schema = object({
  name: string().required('Name is required').default('').label('Name'),
  description: string().notRequired().max(150, 'Description cannot be more than 150 characters').default(''),
  timesEaten: number().required('Times Eaten is required').min(0).default(1).label('Times Eaten'),
  rating: number()
    .required('Rating is required')
    .min(1, 'Rating cannot be less than 1')
    .max(100, 'Rating cannot be more than 100')
    .default(50)
    .label('Rating'),
}).required();
export type FormSchemaType = InferType<typeof schema>;

function ConfigurePasta() {
  return (
    <Container>
      <SpaceBetween size='m'>
        <ControlledFormInput
          name='name'
          fieldProps={{
            label: 'Name',
            description: 'The name of this pasta',
          }}
          inputProps={{
            placeholder: 'Spaghetti',
          }}
        />
        <ControlledFormInput
          name='description'
          optional
          fieldProps={{
            label: 'Description',
            description: 'A short description to summarize this pasta',
          }}
          inputProps={{
            placeholder: 'Long thin noodles',
          }}
        />
        <ControlledFormInput
          name='timesEaten'
          fieldProps={{
            label: 'Times Eaten',
            description: "The number of times you've had this pasta",
          }}
          inputProps={{
            placeholder: '3',
            type: 'number',
          }}
        />
        <ControlledFormInput
          name='rating'
          fieldProps={{
            label: 'Rating',
            description: 'The rating out of 100 you give to this pasta',
          }}
          inputProps={{
            placeholder: '99',
            type: 'number',
          }}
        />
      </SpaceBetween>
    </Container>
  );
}

function CreatePastaContent() {
  const navigate = useNavigate();
  const layoutDispatch = useLayoutDispatch();

  const formMethods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });
  const { getValues } = formMethods;

  const dispatchNotification = (notification: FlashbarProps.MessageDefinition) => {
    const notice: Notification = {
      ...notification,
      uuid: uniqueId(),
      dismissible: true,
    };
    notice.onDismiss = () => {
      layoutDispatch({
        type: 'removeNotification',
        notification: notice,
      });
    };
    layoutDispatch({
      type: 'addNotification',
      notification: notice,
    });
  };

  const createPasta = async () => {
    const { name, description, timesEaten, rating } = getValues() as FormSchemaType;
    try {
      const response = await pastaServiceClient.createPasta(
        schema.cast({
          name: name,
          description: description,
          timesEaten: timesEaten,
          rating: rating,
        }),
      );
      navigate(UIPath.ListPasta);
      dispatchNotification({
        type: 'success',
        content: `Successfully added Pasta '${response.pasta!.name}'.`,
      });
    } catch (err) {
      const apiErr = err as PastaServiceApiError;
      dispatchNotification({
        type: 'error',
        content: `There was an error adding your Pasta. ${apiErr.message}`,
      });
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Wizard
        i18nStrings={{
          submitButton: 'Log Pasta',
        }}
        onSubmit={async () => await createPasta()}
        steps={[
          {
            title: 'Pasta Details',
            content: <ConfigurePasta />,
          },
        ]}
      />
    </FormProvider>
  );
}

export default function CreatePasta() {
  return (
    <Layout
      contentType='wizard'
      navigationOpen={false}
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: BreadcrumbText.Landing, href: UIPath.Landing },
            { text: 'Create', href: '#' },
          ]}
          ariaLabel='Breadcrumbs'
        />
      }
      content={<CreatePastaContent />}
    />
  );
}
